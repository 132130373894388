import ArtThumb from './art.png'
import TwitterThumb from './twitter.png'
import WorkThumb from './work.png'
import ShopThumb from './shop.png'
import Fustuk from './fustuk2.png'
import FustukBackground from './fustuksamplepages.png'
import FustukSeperator from './seperator.png'

export default {
	ArtThumb,
	TwitterThumb,
	WorkThumb,
	ShopThumb,
	Fustuk,
	FustukBackground,
	FustukSeperator
}